.header {
    background-color:rgb(48, 76, 103);
    height: 120px; 
    padding: 5px 10px; 
    font-size: 16px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }

  .secondary_view {
    text-align: left;
    margin-left: 50px;
  }
  
  .main_container {
    display: flex;
    height: 100vh;
  }

  .question-text {
    color: black;      /* Set the text color */
    font-size: 18px;   /* Set the font size */
    margin: 10px 0;    /* Add some margin for spacing */
  }
  
  .button-default {
    background-color: rgb(255, 255, 255); 
    font-size: 12px;
    margin-left: 3px; 
    margin-right: 5px;
    margin-bottom: 250px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    color: white;
  }
  
  .button1 {
    width: 170px; /* Set a fixed width for all buttons */
    height: 45px; /* Set a fixed height for all buttons */  
    background-color: rgb(48, 76, 103); /* Button background color */
    color: white; /* Button text color */
    margin-left: 3px;
    padding: 10px 20px; /* Padding inside the button */
    border: none; /* Removes the default border */
    border-radius: 5px; /* Rounded corners */
    cursor: pointer; /* Changes cursor to pointer on hover */
    font-size: 16px; /* Font size */
    transition: background-color 0.3s ease; /* Smooth transition for hover effect */
  }
  
  .button1:hover {
    background-color: #41a0ba; /* Darker color on hover */
    color:black;
    
  }

  .button2 {
    width: 180px; /* Set a fixed width for all buttons */
    height: 45px; /* Set a fixed height for all buttons */  
    background-color: rgb(48, 76, 103); 
    color: white; 
    margin-left: 3px;
    margin-bottom: 10px;
    padding: 10px 20px; 
    border: none; 
    border-radius: 5px; 
    cursor: pointer; 
    font-size: 16px; 
    transition: background-color 0.3s ease; 
  }
  
  .button2:hover {
    background-color: #41a0ba; 
    color:black;
    
  }

  .error-message {
    color:red;
    font:bold;
    margin-top:auto;
  }
  
  .logo_image {
    margin: 20px;
    width: 300px;
    height: 300px;
  }
  
  input[type="email"],
  input[type="password"],
  input[type="text"],
  input[type="checkbox"],
  select,
  textarea {
    width: 15%;
    padding: 12px;
    margin: 8px 0;
    margin-right: 8px;
    box-sizing: border-box;
    border: 2px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out, box-shadow 0.3s ease-in-out;
  }
  
  input[type="email"]:focus,
  input[type="password"]:focus,
  input[type="text"]:focus,
  input[type="checkbox"]:focus,
  select,
  textarea {
    border-color: #66afe9;
    box-shadow: 0 0 8px 0 rgba(102, 175, 233, 0.6);
    outline: none;
  }

  textarea {
    min-height: 150px;
    width: 500px;
    max-height: 500px;
  }

  .question-list {
    padding: 0;
    margin: 0;
    margin-bottom: 40px; 
    font-weight: bold;
  }
  
  .question-list li {
    font-weight: bold; 
    margin-bottom: 40px; 
    text-align: center; 
  }

  .list-container {
    display: flex;
    justify-content: space-around; 
    max-width: 80%; 
    margin: 0 auto; 
    padding: 20px; 
    line-height: 2;
  }
  
  .list {
    list-style-type: none;
    margin: 10px; 
    margin-bottom: 20px;
    padding: 0;
  }

  .container {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    padding: 20px;
  }
  
  .form-container {
    width: 45%; 
    text-align: left;
    padding: 20px;
    border: 5px solid #ccc;
  }
  
  .answers-container {
    width: 45%; 
    text-align: left; 
    padding: 20px;
    border: 5px solid #ccc;
  }

    
  .version-number {
    position: absolute;
    top: 20px;
    right: 10px;
    color: white;
    font-size: 14px;
    background-color: rgba(0, 0, 0, 0.5); /* Optional: semi-transparent background */
    padding: 5px 10px;
    border-radius: 5px;
  }
  
  .question-container {
    display: flex;
    flex-direction: column;
    gap: 20px; /* Space between questions */
    max-width: 800px;
    margin: 20px auto; /* Center the container */
  }
  
  .question-box {
    background-color: #f7f7f7; /* Light gray background */
    padding: 20px;
    border: 1px solid #ddd; /* Subtle border */
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1); /* Subtle shadow */
  }
  
  .question-text {
    font-size: 18px; /* Slightly larger font for the question */
    font-weight: bold;
    color: black;
    margin-bottom: 10px; /* Space below the question */
  }
  
  .answer-box {
    margin-top: 10px;
  }
  
  .answer-list {
    list-style-type: lower-alpha; /* Use letters (a, b, c, ...) instead of numbers */
    padding-left: 20px; /* Add padding to align the list items */
    margin: 0; /* Remove extra margins around the list */
  }
  
  .answer-item {
    margin-bottom: 5px; /* Add space between the answers */
    font-size: 14px; /* Smaller font size for answers */
    color: #555; /* Subtle gray text for answers */
    display: flex; /* Align the letters and text closer */
    align-items: center; /* Vertically align letters and text */
  }
  
  .answer-item::marker {
    font-weight: bold; /* Make the letters bold */
    margin-right: 5px; /* Add space between the letter and text */
  }
  
  .correct-answer {
    font-size: 14px; /* Smaller font size for correct answer */
    font-weight: bold;
    color: #28a745; /* Green text for correct answer */
    margin-top: 10px;
  }
  