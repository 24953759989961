.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
  margin-right: 60px;
  margin-bottom: 250px;
  align-self: center;
  flex-direction: column;
}

.App-Image {
  position: absolute;
  top: 0;
  left: 0;
  height: 25vmin; 
  pointer-events: none;
  background-color: rgb(48, 76, 103);
  border: white;
  border-style:ridge;
  z-index: 1; 
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear; 
  }
}

.App-header {
  background-color: white;
  min-height: 100vh;
  display: flex;
  padding: 0 20px;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-sidebar {
  position: fixed; /* Keeps the sidebar in place as you scroll */
  top: 0; /* Aligns it to the top of the page */
  left: 0; /* Aligns it to the left side of the page */
  width: 175px; /* Sets the width of the sidebar */
  height: 100vh; /* Ensures it takes up the full height of the viewport */
  background-color: #333; /* Background color of the sidebar */
  z-index: -1; /* Ensures the sidebar stays in the background */
  padding: 20px; /* Optional padding inside the sidebar */
  color: white; /* Text color, if you have content inside the sidebar */
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
